<div (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="flex flex-col sidenav">
    
  <div class="flex items-center flex-none sidenav-toolbar"> 
    <!-- <div *ngIf="loggedUser && loggedUser.logo">
      <img [src]="imageUrl$ | async" class="flex-none h-6 select-none">
    </div> -->
    <ng-container *ngIf="loggedUser">
      <!-- <img [src]="imageUrl$ | async" class="flex-none w-6 select-none"> -->
     <div (click)="redirectDashboard()" class="flex">  
      <!-- <img src="/assets/img/icons/logo_empida.svg" class="h-8 mr-2"> -->
      <h2 class="flex-auto w-full overflow-hidden text-[#fff] capitalize select-none organisationName overflow-ellipsis">{{loggedUser.isDirectFromLink == 1 ? loggedUser.teamName : loggedUser.organisationName}}</h2>
    </div>
  </ng-container>
  <!-- <button (click)="toggleCollapse()"
            *ngIf="showCollapsePin$ | async"
            class="flex-none hidden w-8 h-8 ml-auto -mr-2 leading-none lg:block"
            mat-icon-button
            type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icRadioButtonChecked" size="14p"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icRadioButtonUnchecked" size="14px"></mat-icon>
    </button>  -->
   </div>

  <vex-scrollbar class="flex-auto" style="padding: 10px 20px;">
    <div class="sidenav-items">
      <vex-sidenav-item *ngFor="let item of navigationService.itemsArray; trackBy: trackByRoute"
        [item]="item" [level]="0">
      </vex-sidenav-item>

      
    </div>

    
    
  </vex-scrollbar>
  <!-- user  -->
  <ng-container class="user">
    <!-- User -->
    <div class="flex items-center w-full px-8 py-4 border-t border-white user "  #originRef  (click)="showPopover(originRef)"
    [class.bg-hover]="dropdownOpen">
        <!-- <user></user> -->
        <div class="flex flex-col w-full overflow-hidden">
            <div class="flex items-center w-full overflow-hidden leading-normal text-current whitespace-nowrap text-ellipsis">
              <mat-icon [icIcon]="icPerson" class="flex items-center justify-center mr-3 user_icon"></mat-icon> <h5 class="font-bold">{{this.loggedUser.firstName}} </h5>                
             </div>
            <div class="w-full mt-0.5 whitespace-nowrap text-sm text-ellipsis overflow-hidden leading-normal text-current opacity-50">
              <h6> {{this.loggedUser.email}} </h6>
            </div>
        </div>
    </div>
</ng-container>
</div>


