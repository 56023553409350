import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { RouterModule } from '@angular/router';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LoadingService } from './services/loading.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ConfirmationReplaceChallengeDialogComponent } from './confirmation-replace-challenge-dialog/confirmation-replace-challenge-dialog.component';
import { ChallengeConfirmationDailogComponent } from './challenge-confirmation-dailog/challenge-confirmation-dailog.component';
import { ReplaceChallengeDailogComponent } from './replace-challenge-dailog/replace-challenge-dailog.component';
import { DashboardInfoComponent } from './dashboard-info/dashboard-info.component';
import { IconModule } from '@visurel/iconify-angular';
import { PariseSearchBoxComponent } from './parise-search-box/parise-search-box.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FilterPipe } from './parise-search-box/filter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuccessEmojiComponent } from './success-emoji/success-emoji.component';
import { CropperComponent } from './cropper/cropper.component';
import { WellnessCategoryCreateUpdateComponent } from './wellness-category-create-update/wellness-category-create-update.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { PraiseDetailComponent } from './praise-detail/praise-detail.component';
import { ChallengeInfoDailogComponent } from './challenge-info-dailog/challenge-info-dailog.component';
import { SelectChallengeTypeComponent } from './select-challenge-type/select-challenge-type.component';
import { EventCreateUpdateComponent } from './event-create-update/event-create-update.component';
import { PhysicalWellBeingComponent } from './physical-well-being/physical-well-being.component';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { ChartModule } from './components/chart/chart.module';
import { WidgetQuickLineChartModule } from './components/widgets/widget-quick-line-chart/widget-quick-line-chart.module';
import { WidgetQuickValueCenterModule } from './components/widgets/widget-quick-value-center/widget-quick-value-center.module';
import { WidgetQuickValueStartModule } from './components/widgets/widget-quick-value-start/widget-quick-value-start.module';
import { WidgetLargeGoalChartModule } from './components/widgets/widget-large-goal-chart/widget-large-goal-chart.module';
import { WidgetLargeChartModule } from './components/widgets/widget-large-chart/widget-large-chart.module';
import { DonationDataComponent } from './donation-data/donation-data.component';
import { SelectSeniorLeaderDialogComponent } from './select-senior-leader-dialog/select-senior-leader-dialog.component';
import { HowItWorkDialogComponent } from './how-it-work-dialog/how-it-work-dialog.component';
import { ThemePreviewDialogComponent } from './theme-preview-dialog/theme-preview-dialog.component';
import { QuillModule } from 'ngx-quill';
import { OnboardDialogComponent } from './onboard-dialog/onboard-dialog.component';
import { EditFocusDialogComponent } from './edit-focus-dialog/edit-focus-dialog.component';
import { CommonInfoDialogComponent } from './common-info-dialog/common-info-dialog.component';
import { PreselectedFocusComponent } from './preselected-focus/preselected-focus.component';
import { ShowChallengeDateComponent } from './show-challenge-date/show-challenge-date.component';
import { InivatedOnBoardingFlowComponent } from './inivated-on-boarding-flow/inivated-on-boarding-flow.component';
import {MatStepperModule} from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgOtpInputModule } from 'ng-otp-input';
import { BrowserModule } from '@angular/platform-browser'
import { OnBoardingNotificationComponent } from './components/on-boarding-notification/on-boarding-notification.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ContainerModule } from 'src/@vex/directives/container/container.module';
import {MatRippleModule} from '@angular/material/core';
import { LaunchChallengeSuccessfullyDialogComponent } from './launch-challenge-successfully-dialog/launch-challenge-successfully-dialog.component';
import { SlackMembersDialogComponent } from './slack-members-dialog/slack-members-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddNewTeamsManagerIniviteComponent } from './add-new-teams-manager-inivite/add-new-teams-manager-inivite.component';
import { ManagerOnboardConfirmationDailogComponent } from './manager-onboard-confirmation-dailog/manager-onboard-confirmation-dailog.component';
import { ActionsDetailDialogComponent } from './actions-detail-dialog/actions-detail-dialog.component';




@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatSnackBarModule,
    TranslateModule,
    QuillModule.forRoot(),
    RouterModule,
    ImageCropperModule,
    IconModule,
    MatRadioModule,
    MatCheckboxModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatDividerModule,
    MatChipsModule,
    MatNativeDateModule,
    FormsModule,
    NgxShimmerLoadingModule,
    ChartModule,
    WidgetQuickLineChartModule,
    MatRippleModule,
    WidgetQuickValueCenterModule,
    WidgetQuickValueStartModule,
    WidgetLargeGoalChartModule,
    WidgetLargeChartModule,
    MatStepperModule,
    MatProgressBarModule,
    NgOtpInputModule,
    CarouselModule,
    ContainerModule,
    MatTooltipModule,
    MatProgressSpinnerModule,


  ],
  exports: [
    LayoutModule,
    TutorialComponent,
    LoadingSpinnerComponent,
    MatStepperModule,
  ],
  providers: [LoadingService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: '80vw',
        minWidth: '320px',
        disableClose: true,
        hasBackdrop: true
      }
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        // panelClass: ['snackbar-dark'],
        horizontalPosition: 'right'
      } as MatSnackBarConfig
    },
    DatePipe
  ],
  declarations: [ConfirmationDialogComponent, SuccessDialogComponent, TutorialComponent, LoadingSpinnerComponent, ConfirmationReplaceChallengeDialogComponent, 
    ChallengeConfirmationDailogComponent, ReplaceChallengeDailogComponent, DashboardInfoComponent, PariseSearchBoxComponent, FilterPipe, SuccessEmojiComponent, 
    CropperComponent, WellnessCategoryCreateUpdateComponent, PraiseDetailComponent, ChallengeInfoDailogComponent, SelectChallengeTypeComponent,
    EventCreateUpdateComponent, PhysicalWellBeingComponent, DonationDataComponent, SelectSeniorLeaderDialogComponent, HowItWorkDialogComponent, ThemePreviewDialogComponent,
   OnboardDialogComponent, EditFocusDialogComponent, CommonInfoDialogComponent, PreselectedFocusComponent, ShowChallengeDateComponent,
   InivatedOnBoardingFlowComponent, OnBoardingNotificationComponent, LaunchChallengeSuccessfullyDialogComponent, SlackMembersDialogComponent, AddNewTeamsManagerIniviteComponent, ManagerOnboardConfirmationDailogComponent, ActionsDetailDialogComponent,],
   entryComponents : [AddNewTeamsManagerIniviteComponent],
   
})
export class VexModule {
}
