import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import icRightArrow from "@iconify/icons-ic/keyboard-arrow-right";
import icLeftArrow from "@iconify/icons-ic/keyboard-arrow-left";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { OnBoardingNotificationComponent } from "../components/on-boarding-notification/on-boarding-notification.component";
import { AuthServiceLocal } from "../services/auth.service";
import { MatStepper } from "@angular/material/stepper";
import icVisibility from "@iconify/icons-ic/twotone-visibility";
import icVisibilityOff from "@iconify/icons-ic/twotone-visibility-off";
import { LocalStoreService } from "../services/local-store.service";
import { DOCUMENT } from "@angular/common";
import { ConfigService } from "../services/config.service";
import { NavigationService } from "../services/navigation.service";
import { CommonInfoDialogComponent } from "../common-info-dialog/common-info-dialog.component";
import { Module } from "../utils/systemEnums";
import { ActivatedRoute, Router } from "@angular/router";
import { PasswordValidation } from "../change-password-modal/password-validator";
import { LaunchChallengeSuccessfullyDialogComponent } from "../launch-challenge-successfully-dialog/launch-challenge-successfully-dialog.component";
import { SlackMembersDialogComponent } from "../slack-members-dialog/slack-members-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as XLSX from "xlsx";
import icClose from "@iconify/icons-ic/twotone-close";

import {TooltipPosition} from '@angular/material/tooltip';
import { ManagerOnboardConfirmationDailogComponent } from "../manager-onboard-confirmation-dailog/manager-onboard-confirmation-dailog.component";
import { BehaviorSubject } from "rxjs";



type AOA = any[][];

@Component({
  selector: "vex-inivated-on-boarding-flow",
  templateUrl: "./inivated-on-boarding-flow.component.html",
  styleUrls: ["./inivated-on-boarding-flow.component.scss",
  "../../../node_modules/quill/dist/quill.snow.css",
    "../../@vex/styles/partials/plugins/_quill.scss",
],
changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: Window, useValue: window }],
})
export class InivatedOnBoardingFlowComponent implements OnInit {
  @ViewChild("stepper", { static: false }) stepper: MatStepper;
 
  @ViewChild('description', { static: false }) descriptionData: ElementRef;
  isLinear = false;
  focusHeadCategoryGroup: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;
  seventhFormGroup: FormGroup;
  userRolesFormGroup: FormGroup;

  isShowSeeMoreOption: boolean = false

  launchScreen: any;

  isOptional: Boolean = true;

  form: FormGroup;

  htmlText ="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."

  icRightArrow = icRightArrow;
  icLeftArrow = icLeftArrow;
  selectedIndex: any = 0

  selectedIndexRef = new BehaviorSubject(0)

  teamSize = [
    {
      size: "2-10",
      isSelected: false,
      image: "svgTeamSize1",
  
    },
    {
      size: "11-25",
      isSelected: false,
      image: "svgTeamSize2",
    
    },
    {
      size: "26-49",
      isSelected: false,
      image: "svgTeamSize3",
      
    },
    {
      size: "50+",
      isSelected: false,
      image: "svgTeamSize4",
      
    },
    // { size:'21-30', isSelected : false , image:'assets/img/inivated-user/teamSizes/25_team.png', hoverImage : 'assets/img/inivated-user/teamSizes/25_team_hover.png'}
  ];
 
    

  focusArea: any =[];

  inputType = "password";
  visible = false;
  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;
  icClose = icClose;
  isManagerFocusAreaSelected: any;
  challengeTemplate: any;

  challengeTemplateIndex = 0;
  challengeTemplateData: any;
  teamId: any;

  serviceDesc : any
  challengeData: any;


  titleDesc = ` Concentrate on leading, we'll handle the rest.`;
  description: string = `Choose a focus, take action - we'll cultivate your team's culture,<br> simplify professional development plans (pdp), and automate growth,<br> while keeping you updated.`;

  howItsWorks = [
    {
      title: "Select a focus area",
      description: "",
      image: "../../assets/img/inivated-user/focusArea.svg",
    },
    {
      title: "Pick an action",
      description: "",
      image: "../../assets/img/inivated-user/pickAction.svg",
    },
    {
      title: "We’ll nudge your employees",
      description: "",
      image: "../../assets/img/inivated-user/nudge.svg",
    },
    {
      title: "See real results",
      description: "",
      image: "../../assets/img/inivated-user/realResults.svg",
    },
  ];


  preferredChannel =[ 
    {title : `We'll inform your teammates about the action` }  , 
    {title: 'Nudge them to complete'},
    {title : 'Send you status reports each Friday'}
  ]

  focusCategory = [
    
    {    
      name: "Select Focus",
      image: "../../assets/img/inivated-user/selectFocus.svg",
      desc: `Choose a key area for growth and development.`,
    },
    {    
      name: "Assign Action",
      image: "../../assets/img/inivated-user/assignAction.svg",
      desc: `Pick an action for your team aligned with your goals.`,
    },
    {    
      name: "Set Channel",
      image: "../../assets/img/inivated-user/Channel.png",
      desc: `Opt for Slack, email, etc., to inform and nudge your team.`,
    },
    {    
      name: "Weekly Insights",
      image: "../../assets/img/inivated-user/weeklyInsights.svg",
      desc: `Receive a snapshot of progress every Friday.`,
    }
  ];

   endUpText: string =
    "Enjoy one month free, launch your first action, and<br> unlock two reports—our gift to you.";

    emailDomains = [
      'finn.no',
      'schibsted.com',
      'mailinator.com',    
      // 'gmail.com',
      'yahoo.com',
      'hotmail.com',
      'aol.com',
      'hotmail.co.uk',
      'hotmail.fr',
      'msn.com',
      'yahoo.fr',
      'wanadoo.fr',
      'orange.fr',
      'comcast.net',
      // 'yahoo.co.uk',
      // 'yahoo.com.br',
      // 'yahoo.co.in',
      'live.com',
      'rediffmail.com',
      'free.fr',
      'gmx.de',
      'web.de',
      'yandex.ru',
      'ymail.com',
      'libero.it',
      // 'outlook.com',
      'uol.com.br',
      'bol.com.br',
      'mail.ru',
      'cox.net',
      'hotmail.it',
      'sbcglobal.net',
      'sfr.fr',
      'live.fr',
      'verizon.net',
      'live.co.uk',
      'googlemail.com',
      'yahoo.es',
      'ig.com.br',
      'live.nl',
      'bigpond.com',
      'terra.com.br',
      'yahoo.it',
      'neuf.fr',
      'yahoo.de',
      'alice.it',
      'rocketmail.com',
      'att.net',
      'laposte.net',
      'facebook.com',
      'bellsouth.net',
      'yahoo.in',
      'hotmail.es',
      'charter.net',
      'yahoo.ca',
      'yahoo.com.au',
      'rambler.ru',
      'hotmail.de',
      'tiscali.it',
      'shaw.ca',
      'yahoo.co.jp',
      'sky.com',
      'earthlink.net',
      'optonline.net',
      'freenet.de',
      't-online.de',
      'aliceadsl.fr',
      'virgilio.it',
      'home.nl',
      'qq.com',
      'telenet.be',
      'me.com',
      'yahoo.com.ar',
      'tiscali.co.uk',
      'yahoo.com.mx',
      'voila.fr',
      'gmx.net',
      'mail.com',
      'planet.nl',
      'tin.it',
      'live.it',
      'ntlworld.com',
      'arcor.de',
      'yahoo.co.id',
      'frontiernet.net',
      'hetnet.nl',
      'live.com.au',
      'yahoo.com.sg',
      'zonnet.nl',
      'club-internet.fr',
      'juno.com',
      'optusnet.com.au',
      'blueyonder.co.uk',
      'bluewin.ch',
      'skynet.be',
      'sympatico.ca',
      'windstream.net',
      'mac.com',
      'centurytel.net',
      'chello.nl',
      'live.ca',
      'aim.com',
      'bigpond.net.au',
    ];
    

  managerName: any;
  serviceRolesList: any;

  newWindow: Window;
  slackIntergrationStatus: boolean = false;
  isSlackFlow: boolean = false;

  emailUpload: FormGroup;

  emaildata: AOA = [];
  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
  fileName: string = "SheetJS.xlsx";
  uploadedEmailCount: any;
  emailInput = new FormControl("", [Validators.required, Validators.pattern(/^(\s*[\w\.-]+(\+\d+)?@[\w\.-]+\s*,\s*)*[\w\.-]+(\+\d+)?@[\w\.-]+\s*$/)]);
  chipsEmails: any = [];


  isNotificationType: any;
  challengeLauchDates: FormGroup;
  currentDate = new Date();
  maxDate = new Date(1709149500000);
  recommendedDate = new Date();
  addedChallengeTemplate: any;
  slackPayload: any;

  isChallengeEdit: boolean = true;
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[1]);
  isVaildDomain: boolean = false;

  @ViewChildren('otpInputRef') otpInput: QueryList<ElementRef>;
  otpDigits: number[] = [0, 0, 0, 0]; 
  allKpiList: any;
  selectedCount: any = 0; 
  selectedTeamSize: string;
  selectedFocus: any;
  selectServiceRole: any;
  isSlackModelOpen: boolean = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<InivatedOnBoardingFlowComponent>,
    private _formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public _matDialog: MatDialog,
    private auth: AuthServiceLocal,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    public store: LocalStoreService,
    private navigationService: NavigationService,
    private configService: ConfigService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private window: Window,
    private route: ActivatedRoute,

    private el: ElementRef,
     private renderer: Renderer2
  ) {

  

  }

  ngOnInit(): void {
    // console.log("dailog data ===>",this.data )

    console.log("hello in step count", this.data)

    this.selectedTeamSize = localStorage.getItem('teamSize')
    this.selectedFocus =  this.data?.stepCount ?  this.data?.stepData[3]?.filter((x) => x.isSelected == 1)[0]?.name :  JSON.parse(localStorage.getItem('isManagerFocusAreaSelected'))?.name
    this.challengeTemplateData = JSON.parse(localStorage.getItem('challengeDetails'))
    this.selectServiceRole = JSON.parse(localStorage.getItem('serviceRole'))?.name

    this.serviceDesc = localStorage.getItem('serviceDesc')
 
   
    console.log("hello in on it ", this.selectedFocus)
  
    this.matIconRegistry.addSvgIcon(
      "svgTeamSize1",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/inivated-user/teamSizes/team2-5.svg"
      )
    ),
      this.matIconRegistry.addSvgIcon(
        "svgTeamSize2",
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/inivated-user/teamSizes/team6-10.svg"
        )
      ),
      this.matIconRegistry.addSvgIcon(
        "svgTeamSize3",
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/inivated-user/teamSizes/3team.svg"
        )
      ),
     

      this.matIconRegistry.addSvgIcon(
        "svgTeamSize4",
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/inivated-user/teamSizes/team50.svg"
        )
      ),

      this.matIconRegistry.addSvgIcon(
        "svgCustomArrow",
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/inivated-user/arrow2.svg"
        )
      );
  
    

    this.focusHeadCategoryGroup = this._formBuilder.group({
        headCategory: ["", Validators.required],
      });

    const email = localStorage.getItem("ManagerEmail");
    this.firstFormGroup = this._formBuilder.group({
      email: [email, [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
    });

    this.secondFormGroup = this._formBuilder.group({
      otp: ["", [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
    });

    this.thirdFormGroup = this._formBuilder.group({
      firstName: ["", [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      lastName: ["", [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(/^(?!\s*$).+/),
          Validators.pattern(/^(?=.*[A-Z])(?=.*\d).{6,}$/),
        ],
      ],
    });

    this.fourthFormGroup = this._formBuilder.group({
      teamSize: ["", Validators.required],
    });

    this.fifthFormGroup = this._formBuilder.group({
      focusArea: ["", Validators.required],
    });

    this.sixthFormGroup = this._formBuilder.group({
      selectedChallenge: ["", Validators.required],
    });

    this.seventhFormGroup = this._formBuilder.group({
      teamName: ["", [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
    });

    this.userRolesFormGroup = this._formBuilder.group({
      serviceId: ["", Validators.required],
    });

    this.emailUpload = this._formBuilder.group({
      file: ["", Validators.required],
    });


    this.challengeLauchDates = this._formBuilder.group({
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
    });

    this.cd.detectChanges()
    if (this.data) {
      console.log("You are in invited user page", this.data);
      this.selectedIndexRef.next(this.data.stepCountData)
      this.cd.detectChanges()

      if (this.data.stepCount) {
        
        this.selectedIndex = this.data.stepCount;
      
        this.data.stepData[this.selectedIndex - 2];


        // this.selectedIndexRef.next(this.data.stepCount)


        this.teamId =  this.data.stepData[0]?.id

        this.teamSize.forEach((element) => {
          if (element.size === this.data.stepData[0]?.teamSize) {
            return (element.isSelected = true);
          }
        });

        this.selectedTeamSize = this.data.stepData[0]?.teamSize

        this.seventhFormGroup.get("teamName").setValue(this.data.stepData[0]?.title);

        this.serviceRolesList = this.data.stepData[2]

        let tempHeadServiceRole = this.data.stepData[2]?.filter((x) => x.isSelected == 1);

        if(tempHeadServiceRole){

            console.log("tempHeadServiceRole ===>",tempHeadServiceRole)
          this.selectServiceRole =  tempHeadServiceRole[0]?.name
          this.userRolesFormGroup.get('serviceId').setValue(tempHeadServiceRole[0])
         
        }
  
        if(this.data?.stepData[3]?.length){
          this.focusArea = this.data?.stepData[3];
       

          let tempHeadCategory = this.data.stepData[3]?.filter((x) => x.isSelected == 1);
       
      
              if(tempHeadCategory){
                this.selectedFocus = tempHeadCategory[0]?.name
              
                console.log("tempHeadCategory in res ==>",this.selectedFocus)
        
                this.fifthFormGroup.get("focusArea").setValue(tempHeadCategory[0]?.name)
        
                this.cd.detectChanges()

              }
         }else{
          this.getFocusArea(1)   
        }

       
        this.challengeTemplate = this.data.stepData[4];

        let tempchallengeTemplateData = this.data.stepData[4]?.filter((x) => x.isSelected == 1 );
        console.log("tempchallengeTemplateData in res",this.data.stepData[4])
        this.challengeTemplateData = tempchallengeTemplateData;
        this.cd.detectChanges();
      }else{
       this.selectedIndex = this.data.stepCountData
       this.selectedIndexRef.next(this.data.stepCountData)
       this.cd.detectChanges()
      }
    }

    this.matIconRegistry.addSvgIcon(
      "svgCustomArrow",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/inivated-user/arrow2.svg"
      )
    );

    
   

    this.selectedIndexRef.subscribe(res =>{
      console.log('hello in selected index ref ==>', res)
       if(res == 2){
        console.log("hello in step two")
        const tempTeamName = localStorage.getItem('teamName')
        if(tempTeamName){
          this.seventhFormGroup.get('teamName').setValue(tempTeamName)
        }
      }
      else if(res == 3){
        this.selectedTeamSize = localStorage.getItem('teamSize')
        if(this.selectedTeamSize){
          this.fourthFormGroup.get("teamSize").setValue(this.selectedTeamSize);
        }

      }
      else if(res== 4){
        if(!this.serviceRolesList?.length){   
          this.getUserServiceRoles()
        }
      }
      else if(res== 6 ){
          const tempFocusHeadCategory = JSON.parse(localStorage.getItem('focusHeadcategoryId'))
          console.log("hello in res 6")
          if(!this.data.stepCount){

            this.getFocusArea(1)
          }
      }
      else if(res == 7){
        const  tempIsManagerFocusAreaSelected =  JSON.parse(localStorage.getItem('isManagerFocusAreaSelected'))
        this.isManagerFocusAreaSelected = tempIsManagerFocusAreaSelected
       
        if(tempIsManagerFocusAreaSelected?.name ){

          console.log("this.selectedFocus  in beh", tempIsManagerFocusAreaSelected?.name )
         this.selectedFocus = tempIsManagerFocusAreaSelected?.name 
        }
       
       
       if(this.challengeTemplate){
         this.challengeTemplateData =  this.challengeTemplate[this.challengeTemplateIndex]
         console.log("this.challengeTemplate ===>", this.challengeTemplate[this.challengeTemplateIndex])

         this.getManagerChallengeTemplate(this.isManagerFocusAreaSelected?.id, this.challengeTemplate[this.challengeTemplateIndex] );
       }else{
        this.getManagerChallengeTemplate(this.isManagerFocusAreaSelected?.id)
       }
      
      //  }
      } 
      else if(res == 8){
        this.getRecommendedDate();
       } 
     })
    
     this.challengeLauchDates.get("startDate").valueChanges.subscribe((res) => {
      console.log("hello in startdate response ===>", res);

      const endDate = this.addDaysToTimestamp(res, 28); // 4 week 4*7 = 28
      console.log("this is the endDate", new Date(endDate));
      this.challengeLauchDates.get("endDate").setValue(new Date(endDate));
    });
   
    setTimeout(() => {
      var tempInput:any = this.otpInput.toArray()[0]  
      tempInput.inputType = 'number';
    }, 1000);

  }
 
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.cd.detectChanges()
  }

 
  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent): void {
    this.renderer.addClass(document.body, 'no-scroll');
  }

  @HostListener('blur', ['$event'])
  onBlur(event: FocusEvent): void {
    this.renderer.removeClass(document.body, 'no-scroll');
  }


  focusLastName(type) {
    console.log("hello im focus last name ", type)
    if(type == 'lastName'){
      const lastNameInput = this.el.nativeElement.querySelector('[formControlName="lastName"]');

      console.log("in type of last name", lastNameInput)
      if (lastNameInput) {
        lastNameInput.focus();
      }
    }else{
      const passwordInput = this.el.nativeElement.querySelector('[formControlName="password"]');
      console.log("in type of last name", passwordInput)
      if (passwordInput) {
        passwordInput.focus();
      }
    }
    
  }

  // add 4 weeks dayes in today's date. if today date is selected for launch challenge.
  addDaysToTimestamp(timestamp, days) {
    const milliseconds = days * 24 * 60 * 60 * 1000; // Convert days to milliseconds
    const newTimestamp = new Date(timestamp);
    newTimestamp.setTime(newTimestamp.getTime() + milliseconds);
    return newTimestamp;
  }

  startStepper(value) {
    console.log("hello value in start stepper ", value)
    this.focusHeadCategoryGroup.get("headCategory").setValue(value);
    if (value) {
    
      localStorage.setItem('focusHeadcategoryId',JSON.stringify(this.focusHeadCategoryGroup.value.headCategory))

      let params = {
        kpiHeadCategoryId: this.focusHeadCategoryGroup.value.headCategory?.id,
      };

      this.auth.saveManagerHeadCategory(params).subscribe((res: any) => {
        if (res.status == 200) {
          console.log("res data in submit email in board  ===>", res.data);
          localStorage.setItem('stepCount', '6')
          if(this.data.stepCount){

            this.getFocusArea(1)
          }
          this.stepper.next();
        } else {
          this._snackBar.open(res.message, "Okay", { duration: 3000 });
        }
      });
    }
  }

  selectionChange(event: any) {
    console.log("event of selection change", event);
    this.selectedIndex = event.selectedIndex;
    this.selectedIndexRef.next(event.selectedIndex)
    // event.stopPropagation()
  }

  // openInivatedUserOnBoarding() {
  //   this._matDialog.open(InivatedUserOnboardingComponent, {
  //     width: "830px",
  //     backdropClass: "aboard_wrapper_dialog",
  //   });
  //   this.dialogRef.close();
  // }

  getCurrentProgressBar(value) {
    return value * 9.09;
  }

  onOtpChange(value) {
    console.log("value in on otp change ===>", value);

    if (value.length == 4) {
      this.secondFormGroup.get("otp").setValue(value);
    } else {
      this.secondFormGroup.get("otp").setValue(null);
    }
  }

  selectTeamSize(indexValue) {
    this.teamSize.forEach((element, index) => {
      if (index == indexValue) {
        element["isSelected"] = true;
        this.fourthFormGroup.get("teamSize").setValue(element.size);
        this.selectedTeamSize = element.size
        this.cd.detectChanges();
      } else {
        element["isSelected"] = false;
        this.cd.detectChanges();
      }
    });
    console.log(this.teamSize);
  }

  selectFocusArea(indexValue) {
    this.isManagerFocusAreaSelected = "";
    this.focusArea.forEach((element, index) => {
      if (index == indexValue) {
        element["isSelected"] = true;
        this.fifthFormGroup.get("focusArea").setValue(element.name);
        this.isManagerFocusAreaSelected = element;
        localStorage.setItem('isManagerFocusAreaSelected',JSON.stringify(element))

        this.selectedFocus = element.name;
        
      } else {
        element["isSelected"] = false;
      }
    });
    this.cd.detectChanges();
  }

  getUserServiceRoles() {
    this.auth.getServiceList().subscribe((res: any) => {
      if (res.status == 200) {
        const tempServiceRoleSelected = JSON.parse(localStorage.getItem('serviceRole'))
        this.selectServiceRole = JSON.parse(localStorage.getItem('serviceRole'))?.name
        
        if(tempServiceRoleSelected){          
         res.data.service.forEach(element => {
            if (element.id == tempServiceRoleSelected.id) {
              element["isSelected"] = true;
              this.userRolesFormGroup.get("serviceId").setValue(element);
            }else {
              element["isSelected"] = false;
            }
            
          })
          this.serviceRolesList =   res.data.service
          this.cd.detectChanges();
        }else{
          this.serviceRolesList = res.data.service;
        }

        this.cd.detectChanges();
      } else {
        this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });
  }


  selectFocusKpiArea(index){

    if (this.selectedCount < 3) {
      this.allKpiList[index].isSelected = this.allKpiList[index].isSelected === 1 ? 0 : 1;
    
      this.selectedCount = this.allKpiList.filter(item => item.isSelected === 1).length;
      this.cd.detectChanges();
    
    } else if (this.selectedCount === 3 && this.allKpiList[index].isSelected === 1) {
      this.allKpiList[index].isSelected = 0;
      this.selectedCount--;
      this.cd.detectChanges();
    
    
    
    } else {
      this.allKpiList[index].isSelected = 0;
      this.cd.detectChanges();
    
      this._snackBar.open("You can select 3 options max", "Okay", { duration: 2000 });
    }
    
    }

    saveKpi(){
        console.log("allKpiList ===>", this.allKpiList)

        let tempFocusKpiSelected =[]
        this.allKpiList.forEach(element => {
            if(element.isSelected == 1){
              tempFocusKpiSelected.push(element.id)
            }
        }); 


       
        let payload = {
          focusKpiIds:  tempFocusKpiSelected.toString()
        }

        this.auth.saveMangerFocusArea(payload).subscribe((res: any) => {
          if (res.status == 200) {
            console.log("res data in submit email in board  ===>", res.data);
            this.isShowSeeMoreOption = false ;
            this.getFocusArea(1)
            this.cd.detectChanges()
          } else {
            this._snackBar.open(res.message, "Okay", { duration: 3000 });
          }
        });

        
    }

  
  selectRoles(indexValue) {
    this.serviceRolesList.forEach((element, index) => {
      if (index == indexValue) {
        element["isSelected"] = true
        this.userRolesFormGroup.get("serviceId").setValue(element);
        localStorage.setItem('serviceRole',JSON.stringify(element))
        this.selectServiceRole = JSON.parse(localStorage.getItem('serviceRole'))?.name
     
      } else {
        element["isSelected"] = false;
      }
    });

    this.cd.detectChanges();
  }

  saveServiceRole() {
    let params = {
      serviceId: this.userRolesFormGroup.value.serviceId.id,
    };

    this.auth.storeServiceRole(params).subscribe((res: any) => {
      if (res.status == 200) {
        console.log("res data in submit email in board  ===>", res.data);
        this.stepper.next();
        localStorage.setItem('stepCount', '5')
        if(this.data.stepCount){
          this.getFocusArea(1)
        }
       
      } else {
        this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });
  }

  

  sendEmail(requestType) {
    console.log("hello in send email")
    let params = {
      email: this.firstFormGroup.value.email,
      kpiHeadCategoryId: this.focusHeadCategoryGroup.value.headCategory.id,
    };

    this.auth.ManagerEmailVerify(params).subscribe((res: any) => {
      if (res.status == 200) {
        this._snackBar.open(res.message, "Okay", { duration: 3000});
        console.log("res data in submit email in board  ===>", res.message);
        // localStorage.setItem('ManagerToken', res.data.token)
        if (requestType == "new") {
          this.stepper.next();
          
        }
      } else {
        this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });
  }

  verifyOtp() {
    let params = {
      otp: Number(this.secondFormGroup.value.otp),
      emailId: localStorage.getItem("ManagerEmail"),
    };

    this.auth.verifyManagerOtp(params).subscribe((res: any) => {
      if (res.status == 200) {
        console.log("res of manager email verify", res);
        localStorage.setItem("ManagerToken", res.data.token);
        this.stepper.next();
        localStorage.setItem('stepCount', '1')
      } else {
        this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });
  }

  savaManagerDetails() {
    this.auth
      .managerDetails(this.thirdFormGroup.value)
      .subscribe((res: any) => {
        if (res.status == 200) {
          console.log("res of manager email verify savaManagerDetails", res);

          this.managerName = res.data.firstName;
          localStorage.setItem("orgId", res.data.organisationId);
          localStorage.setItem("managerId", res.data.id);
          localStorage.setItem("tempPassword", this.thirdFormGroup.value.password);
          localStorage.setItem('stepCount', '2')
          this.stepper.next();
        } else {
          this._snackBar.open(res.message, "Okay", { duration: 3000 });
        }
      });
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = "password";
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = "text";
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  seeMore(){
    this.fifthFormGroup.get("focusArea").setValue('')
    this.cd.detectChanges()
    this.isManagerFocusAreaSelected = ''
    this.getFocusArea(2)
    this.isShowSeeMoreOption = !this.isShowSeeMoreOption
  }

  getFocusArea(roleType) {

      
    this.auth.getFocusAreaList(roleType).subscribe((res: any) => {
      if (res.status == 200) {

        if(roleType == 1){

          let tempFocusArea = res.data.kpis.map((element) => ({
            ...element,
            isSelected: false,
          }));
          
  
          if(this.isManagerFocusAreaSelected?.id){
            tempFocusArea.forEach((element:any)=>{
  
             if(element.id == this.isManagerFocusAreaSelected?.id){              
                    element['isSelected'] = true
                }else{
                  element['isSelected'] = false
                }
            })        
            this.focusArea = tempFocusArea;
            this.cd.detectChanges()
  
          }else{
            this.focusArea = tempFocusArea;
            this.cd.detectChanges()
          }
  


        }else if(roleType == 2){
          this.allKpiList = res.data.kpis
          this.selectedCount =  res.data.kpis.filter(item => item.isSelected === 1).length;
          this.cd.detectChanges()
        }
      


        
     
      } else {
        this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });
  }

  // getAllServiceAreaList(){
  //   this.auth.getKpiAreaListData().subscribe((res: any) => {
  //     if (res.status == 200) {
     
  //     }
  //     else {
  //       this._snackBar.open(res.message, "Okay", { duration: 3000 });
  //     }
  //   })

  // }


  saveTeamSize() {
    let params={
      teamSize: this.fourthFormGroup.value.teamSize,
    }

    this.auth.addTeamSize(params).subscribe((res: any) => {
      if (res.status == 200) {

        localStorage.setItem('teamSize', params.teamSize)
        localStorage.setItem('stepCount', '4')
        this.stepper.next();

      } else {
        this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });
   

  }

  saveAllTeamData() {
    let params = {
      teamName: this.seventhFormGroup.value.teamName,      
    };

    this.auth.saveTeamName(params).subscribe((res: any) => {
      if (res.status == 200) {
        
        this.teamId = res.data.id;
        
        localStorage.setItem('teamId', res.data.id)
        localStorage.setItem('teamName', params.teamName)

        localStorage.setItem('stepCount', '3')
        this.stepper.next();
      } else {
        this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });
  }

  saveFocusArea() {
    this.challengeTemplate = ''
    
      let params = {
        kpiIds: this.isManagerFocusAreaSelected?.id,
      };
       

      this.auth.saveMangerFocusArea(params).subscribe((res: any) => {
        if (res.status == 200) {
      
          this.selectedFocus =  this.isManagerFocusAreaSelected?.name
          
          if(this.data.stepCount){
            this.getManagerChallengeTemplate(this.isManagerFocusAreaSelected?.id);
          }
          
          this.stepper.next();
          localStorage.setItem('stepCount', '7')

        } else {
          this._snackBar.open(res.message, "Okay", { duration: 3000 });
        }
      });
    

    
  }

  getManagerChallengeTemplate(id, selectedTypeData?) {
    let params = {
      kpiId: id,
    };

    
    this.auth.getChallengeTemplate(params).subscribe((res: any) => {
      if (res.status == 200) {
             

      if(selectedTypeData){
        const tempData = res.data.templates
        
        tempData.forEach(element => {          
          console.log("isSelected ===>" , element.id + ' ====='+ selectedTypeData.id)
          if(element.id == selectedTypeData.id ){
            element['isSelected'] = 1
          }else{
            element['isSelected'] = 0
          }
           
        });

       
        this.challengeTemplate = res.data.templates;
        this.cd.detectChanges()


      }
      else{
        const tempData = res.data.templates
        
        tempData.forEach(element => {
          element['isSelected'] = 0
        });

        
        this.challengeTemplate = res.data.templates;
        this.cd.detectChanges()
      }

     
      } else {
        this.challengeTemplate =[]
        this._snackBar.open(res.message, "Okay", { duration: 3000});
      }
    });
  }
  

  challengeDetailsBack(){
    if( !this.isChallengeEdit ){
       this.isChallengeEdit= !this.isChallengeEdit 
       this.challengeTemplateIndex = 0
     }else{
       this.challengeTemplateIndex = 0
     }
   }
 

  next() {    
   
    
    if (this.challengeTemplate.length != this.challengeTemplateIndex + 1) {
      this.challengeTemplateIndex = this.challengeTemplateIndex + 1;

      if( !this.isChallengeEdit){
        this.isChallengeEdit = !this.isChallengeEdit
      }
    }
  }

  pre() {
    if (this.challengeTemplateIndex > 0) {
      this.challengeTemplateIndex = this.challengeTemplateIndex - 1;

      if( !this.isChallengeEdit){
        this.isChallengeEdit = !this.isChallengeEdit
      }
    }
  }


  selectChallenge(){
    console.log(this.challengeTemplate[this.challengeTemplateIndex])

    this.challengeTemplate.forEach(element => {
      if(element.id == this.challengeTemplate[this.challengeTemplateIndex]?.id ){
        element.isSelected == 0 ? element.isSelected = 1  : element.isSelected = 0 ;
        
      }else{
        element.isSelected = 0
      }
    })
    this.cd.detectChanges();
   
  


  }

  launchChallenge() {  


    this.challengeTemplate.forEach(element => {
      if(element.isSelected == 1 ){
        this.sixthFormGroup.get("selectedChallenge").setValue(this.challengeTemplate[this.challengeTemplateIndex].id);
        this.challengeTemplateData = this.challengeTemplate[this.challengeTemplateIndex];
        localStorage.setItem('challengeDetails', JSON.stringify(this.challengeTemplate[this.challengeTemplateIndex]))

        this.launchTemplateChallengeData()
        this.getRecommendedDate();
    
        localStorage.setItem('stepCount', '8')  
        this.stepper.next();       
        this.cd.detectChanges();
        
      }
  });

  }

  launchTemplateChallengeData() {
    console.log("this.challengeTemplateData", this.challengeTemplateData);

    let params = {
      teamId: this.teamId ? this.teamId : localStorage.getItem('teamId'),
      templateId: this.challengeTemplateData.id,
      title : this.challengeTemplateData.title,
      description: this.challengeTemplateData.description
    };

    this.auth.addManagerChallengeTemplate(params).subscribe((res: any) => {
      if (res.status == 200) {
        console.log("hello in save team data ==>", res.data);
        this.challengeData = res.data;
      } else {
        this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });
  }

  clearToken() {
    localStorage.clear();
    this.dialogRef.close();
    this.router.navigate(["/manager.ai"]);
  }

  login(res) {
    let params = {
      email: this.firstFormGroup.value.email,
      password: this.thirdFormGroup.value.password.length ? this.thirdFormGroup.value.password : localStorage.getItem('tempPassword') ,
    };

    this.auth.organisationLogin(params).subscribe((response: any) => {
      if (response.status === 200) {
        localStorage.clear()

        this.store.setItem("loggedUser", response.data);
        this.store.setItem("token", response.data.token);
        this.store.setItem("language", "en");
        this.store.setItem("selectedModuleID", Module.Dashboard);
        this.store.setItem('domain',response.data.email.split('@')[1]);
        
        this.router.navigate(["/"]);

        if (response.data.colorCode) {
          const RgbColor = this.configService.hexToRGB(response.data.colorCode);
          if (this.document) {
            this.document.documentElement.style.setProperty(
              "--color-primary",
              RgbColor
            );
            this.document.documentElement.style.setProperty(
              "--color-primary-contrast",
              "255,255,255"
            );
          }
        }
        this.navigationService.setMenu();

       
        this.navigationService.isAdminLogin = response.data.isAdmin === 1;

      
        this.store.setItem("sidebarData", this.navigationService.sidebarData);
  
      
        this.dialogRef.close(res);
      }

    });
  }

  getData(res) {
    return this.auth.getIntergrationStatus().subscribe((response: any) => {
      if (response.status === 200) {
        this.isSlackModelOpen = true 

        this.cd.detectChanges()
        this.slackIntergrationStatus = response.data.isSlackIntegrated;
        console.log(
          "get Intergration status ",
          response.data.isSlackIntegrated
        );

        if (this.slackIntergrationStatus && res) {
        
          this.openSlackMembersListing();
        }
        // this.__snackBar.open(response.message);
      } else if (response.status === 400) {
        this.slackIntergrationStatus = response.data.isSlackIntegrated;
        // this.__snackBar.open(response.message);
      } else {
        this._snackBar.open(response.message);
      }
    });
  }

  openSlackMembersListing() {
    this.auth.getSlackChannelMembersList().subscribe((res: any) => {
      if (res.status == 200) {
        console.log("res members list", res.data);
        
        const dailog = this._matDialog.open(SlackMembersDialogComponent, {
          width: "80%",
          height: "80vh",
          panelClass: ['inivatedManagerFlow',"slackMembersWrapper"],
          hasBackdrop: true,
          data: {
            members: res.data.members?.length ? res.data.members : [],
          },
        });

        dailog.afterClosed().subscribe((members) => {
          if (members.length) {
            this.slackPayload = {
              templateId: this.addedChallengeTemplate?.id,
              notificationType: 2,
              slackUserIds: [],
            };

            members.forEach((item) => {
              this.slackPayload.slackUserIds.push({
                id: item.id,
                email: item.profile.email,
              });
            });
            this.stepper.next();
            console.log(
              "this is final payload in slack case",
              this.slackPayload
            );

            this.launchTemplate(this.isNotificationType)
            
          }
        });
      }
    });
  }

  @HostListener("window:focus", ["$event"])
  onWindowFocus(event) {
 
    console.log("event", event);
    if (!this.slackIntergrationStatus && this.isSlackFlow) {
      this.getData(true);
    }
  }

  sendNotification(type) {
    this.isNotificationType = type;
    this.cd.detectChanges();
    this.getAddedChallengeTemplate();
    if (type == "email") {
      this.stepper.next();
  
    } else {
      console.log("this.slackIntergrationStatus", this.slackIntergrationStatus);
      if (this.slackIntergrationStatus) {
        this.getData(true);
      } else {
        // slack case.
        const loggedUser: any = JSON.parse(localStorage.getItem("loggedUser"));

        console.log("loggedUser ===>", loggedUser);
        const ordId = localStorage.getItem("orgId")?.length ? localStorage.getItem("orgId") : loggedUser?.organisationId;

        const state = {
          organizationId: ordId,
          isDemo: 1,
          managerId: loggedUser?.id ? loggedUser?.id : localStorage.getItem("managerId"),
        };

        console.log("state payload in popup ===>", state);
        this.newWindow = this.window.open(
          `https://slack.com/oauth/v2/authorize?client_id=1179687372869.3192957739207&scope=channels:history,channels:join,channels:read,chat:write,groups:write,im:write,incoming-webhook,mpim:write,team:read,users:read,users:read.email&user_scope=channels:write,im:write,team:read,users:read,users:read.email,chat:write&state=${JSON.stringify(
            state
          )}`,
          "_blank",
          "location=yes,height=900,width=920,scrollbars=yes,status=yes"
        );
        this.isSlackFlow = true;
      }
    }
  }

  getRecommendedDate() {
    console.log("hello in recommendate ")
    this.auth.getRecommendStartDate().subscribe((response: any) => {
      if (response.status == 200) {
        this.recommendedDate = response.data.recommendedDate;
      } else {
        this._snackBar.open(response.message, "Okay", { duration: 2000 });
      }
    });
  }

  getAddedChallengeTemplate() {
    this.auth.getTemplateForLaunch().subscribe((response: any) => {
      if (response.status == 200) {
        this.addedChallengeTemplate = response.data.templateData;
      } else {
        this._snackBar.open(response.message, "Okay", { duration: 2000 });
      }
    });
  }

  addEmail() {
   
    if(this.emailInput.invalid){       
      this._snackBar.open('Please enter vaild email', "Okay", { duration: 2000 });
    }else{

      
      if(this.emailInput.value.includes('@')){
        var domain = localStorage.getItem('domain')
        domain  = domain.replace(/"/g, "")
        
        console.log("domain",domain)
        if(this.emailInput.value.includes(domain)){
          
          var tempEmailAddress = this.emailInput.value.split(',').map((email: string) => email.trim())
          

          tempEmailAddress.forEach(element => {
            if(element.includes(domain)){
              this.chipsEmails.push(element);  
      
            } else{
              this.isVaildDomain = true
              this.emailInput.setValue(element);
              this._snackBar.open(element +' is not an vaild work email', "Okay", { duration: 4000 });
              this.cd.detectChanges()
              return
            }
          });
        
        
            this.emailInput.setValue("");
            this.emaildata = [];
            this.emailUpload.get("file").setValue("");
            this.isVaildDomain = false;
            this.cd.detectChanges();
          
          
        }else{
          this.isVaildDomain = true
          this._snackBar.open('Please enter vaild work email', "Okay", { duration: 2000 });
          this.cd.detectChanges()
           return
     
       
        }
      }      
    }
  }

  onRemoveEmail(i) {
    this.chipsEmails.splice(i, 1);
  }

  onFileChange(evt: any) {
    console.log("file changed", this.emailUpload.get("file").value);
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    console.log("target", target.files);
    if (target.files.length > 1) {
      this._snackBar.open("Cannot use multiple files", "OKAY");
      this.uploadedEmailCount = "";
      this.emailUpload.get("file").setValue("");
      // throw new Error('Cannot use multiple files')
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      if (this.chipsEmails.length) {
        this.chipsEmails = [];
      }
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.emaildata = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.emaildata = this.emaildata.filter((element: any) => element != "");
      if (this.emaildata?.length) {
        let pattern: any =
          /^(?!.*@.*@.*$)(?!.*@.*--.*\..*$)(?!.*@.*-\..*$)(?!.*@.*-$)((.*)?@.+(\..{1,11})?)$/;
        function checkEmailValidation(email) {
          return pattern.test(email);
        }

        if (this.emaildata.every(checkEmailValidation)) {
          this._snackBar.open("Success", "OKAY");
          this.uploadedEmailCount = this.emaildata.length;
          console.log("this.data", this.emaildata);
          let emailArr = [];
          this.emaildata.map((element) => {
            emailArr.push(element[0].toString());
          });
          this.emailUpload.get("file").setValue(emailArr);
        } else {
          this.emaildata = [];
          this._snackBar.open("Invalid Email. Select Again", "OKAY");
          this.emailUpload.get("file").setValue("");
          this.uploadedEmailCount = "";
        }
      } else {
        this.emaildata = [];
        this._snackBar.open("No Data Found In Sheet. Select Again", "OKAY");
        this.emailUpload.get("file").setValue("");
        this.uploadedEmailCount = "";
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  saveEmails() {

   
    const filterEmails: any = [];
    if (this.emaildata.length) {
      this.emaildata.forEach((innerArray, index) => {
        filterEmails[index] = innerArray[0];
      });

      localStorage.setItem("emailId", filterEmails);
    } else {
      localStorage.setItem("emailId", this.chipsEmails);
    }
    

    
    if(this.chipsEmails?.length || filterEmails?.length){
      console.log("hello in test ")
       this.launchTemplate(this.isNotificationType)
    }else{
      this.addEmail()
      this.launchTemplate(this.isNotificationType)
    }
    // this.stepper.next();
    

   
  }

  launchTemplate(type) {
    console.log("value in launch template ==>", type);

    if (type == "email") {
      const filterEmails = [];
      if (this.emaildata.length) {
        this.emaildata.forEach((innerArray, index) => {
          filterEmails[index] = innerArray[0];
        });
      }

    

      const payload = {
        templateId: this.addedChallengeTemplate?.id,
        startDate: new Date(
          this.challengeLauchDates?.value?.startDate
        ).getTime(),
        endDate: new Date(this.challengeLauchDates?.value?.endDate).getTime(),
        notificationType: 1,
        emailIds: this.chipsEmails.length ? this.chipsEmails : filterEmails,
      };

      console.log("payload data ===>", payload);


      this.auth.launchTemplateChallenge(payload).subscribe((res: any) => {
        if (res.status == 200) {
          this.isSlackModelOpen = false 
          const congratulationDialog = this._matDialog.open(
            LaunchChallengeSuccessfullyDialogComponent,
            {
              panelClass:'inivatedManagerFlow',
              width: "800px",
              data:`for launching your first action. We’ll keep you updated.`
            }
          );
          congratulationDialog.afterClosed().subscribe(() => {
            const isUserLoggedIn : any = JSON.parse(localStorage.getItem("loggedUser"));

            if(isUserLoggedIn){
              this.dialogRef.close(res);
            }else{
              
              this.login(res);
            }

          });
        } 
        else {
          this._snackBar.open(res.message, "OKAY", { duration: 2000 });
        }
      });
    } 
    else {
    
      this.slackPayload.startDate = new Date(this.challengeLauchDates.get("startDate").value).getTime(),
      this.slackPayload.endDate = new Date(this.challengeLauchDates.get("endDate").value).getTime();

      this.auth.launchTemplateChallenge(this.slackPayload)
        .subscribe((res: any) => {
          if (res.status == 200) {
            this._snackBar.open(res.message, "OKAY", { duration: 2000 });

            const congratulationDialog = this._matDialog.open(
              LaunchChallengeSuccessfullyDialogComponent,
              {
                panelClass:'inivatedManagerFlow',
                width: "800px",
                data:`for launching your first action. We’ll keep you updated.`
              }
            );
            congratulationDialog.afterClosed().subscribe(() => {
              // this.managerDashboardData();
              const isUserLoggedIn : any = JSON.parse(localStorage.getItem("loggedUser"));

              if(isUserLoggedIn){
                this.dialogRef.close(res);
              }else{
                
                this.login(res);
              }
            });
          } else {
            this._snackBar.open(res.message, "OKAY", { duration: 2000 });
          }
        });
    }
  }

  onSave( eventData){
    this.challengeTemplate[this.challengeTemplateIndex].description = eventData?.target?.innerHTML
  }

  updateCount(){
    
      this.auth.updateStepCountData({stepCount : 4}).subscribe((res: any) => {
        if (res.status == 200) {
          this.stepper.next()
          
        } else {
          this._snackBar.open(res.message, "Okay", { duration: 3000 });
        }
      });
  }

 
}
