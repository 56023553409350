export const ApiEndPointUrl = 'https://api.empida.com/api/v5.36'; // prod

// export const ApiEndPointUrl = 'https://apidev.empida.com/api/v5.36'; // stage

export enum Pattern {
    NumberWithDecimal = '^0$|^[1-9]\\d*$|^\\.\\d+$|^0\\.\\d*$|^[1-9]\\d*\\.\\d*$',
    Email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
    Mobile = '[0-9]+',
    MobileMinLength = 10,
    Password = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$', // '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$'.
    Numbers = '^[1-9]*$',
}

export enum language {
    English = 'en',
    Norway = 'nb'
}

// User Status
export enum UserStatus {
    Inactive = 0,
    Active = 1,
    Block = 2
}




export enum questionType {
    MultipleChoice = 1,
    Answerable = 2
}


export enum leaderBoardType {
    pointLeaderBoard =  1 , 
    percentageLeaderBoard = 2
}



export enum wellnessType {
    Quiz    = 1,
    Answer  = 2
}
export enum challengeType {
  Individual = 1,
  Team = 2,
  Self = 3,
}


export enum challengesPrivacyType {
  Public = 1,
  Private = 2,
}

export enum challengesStatus {
  Active = 1,
  Delete = 2,
}

export enum invitationType {
  User = 1,
  Team = 2,
}

export enum booleanValue {
    No = 0,
    Yes = 1
}

//

export enum challengeCategory {
    STEP = 1,
    WATER = 2,
    COUNTING = 3,
    POINTS = 4,
    SLEEP = 5,
    SCREEN_TIME = 6,
    GOAL = 7,
    QUIZ = 8,
    PUSHUP_COUNTING=9,
    SOCIAL_BREAK = 10,
    GRATITUDE = 11,
    MEDITATION = 12,
    COMBINATION = 13,
    LANGUAGE_CHALLENGE = 14,
    CO2_CHALLENGE = 15,
    NETWORK_CHALLENGE = 16,
    STAIRS = 17,
    SO_GR = 18,
    LA_COM = 19,
    PICTURE_CHALLENGE = 20    
}

export enum currency {
    US_Dollar = 'USD',
    Euro = 'EUR',
    Norwegian = 'NOK',
    Japanese_Yen = 'JPY',
    Pound_Sterling = 'GBP',
    Australian_Dollar = 'AUD',
    Canadian_Dollar = 'CAD',
    Swiss_Franc = 'CHF',
    Chinese_Renminbi = 'CNH',
}


export enum monthFilter {
    JAN = 1,
    FEB = 2,
    MAR = 3,
    APR = 4,
    MAY = 5,
    JUN = 6,
    JLY = 7,
    AUG = 8,
    SEPT = 9,
    OCT = 10, 
    NOV = 11,
    DEC = 12   
}

export const monthFilterList = [
    {name:'JAN',value:monthFilter.JAN},
    {name:'FEB',value:monthFilter.FEB}, 
    {name:'MAR',value:monthFilter.MAR},
    {name:'APR',value:monthFilter.APR}, 
    {name:'MAY',value:monthFilter.MAY},
    {name:'JUN',value:monthFilter.JUN}, 
    {name:'JLY',value:monthFilter.JLY},
    {name:'AUG',value:monthFilter.AUG},
    {name:'SEPT',value:monthFilter.SEPT}, 
    {name:'OCT',value:monthFilter.OCT},
    {name:'NOV',value:monthFilter.NOV},
    {name:'DEC',value:monthFilter.DEC},
]
export enum slackEventData {
    SLACK_INTEGRATION = 0,
    COMPANY_CHALLENGE_STARTS = 1,
    TEAM_CHALLENGE_STARTS = 2,
    COMPANY_CHALLENGE_ENDS = 3,
    TEAM_CHALLENGE_ENDS = 4,
    DONATION_GOAL_STARTS = 5,
    DONATION_GOAL_REACHED = 6,
    TEAM_POSITION = 7,
    MANAGER_SENT_EMO = 8,
    REWARD_LEADERBOARD = 9,
    NEW_WELLNESS_CATEGORY_LAUNCHED = 10,
}

export const slackEventList = [
    {name:'SLACK INTEGRATION',value:slackEventData.SLACK_INTEGRATION},
    {name:'COMPANY ACTION STARTS',value:slackEventData.COMPANY_CHALLENGE_STARTS},
    {name:'TEAM ACTION STARTS',value:slackEventData.TEAM_CHALLENGE_STARTS},
    {name:'COMPANY ACTION ENDS',value:slackEventData.COMPANY_CHALLENGE_ENDS},
    {name:'TEAM ACTION ENDS',value:slackEventData.TEAM_CHALLENGE_ENDS},
    {name:'DONATION GOAL STARTS',value:slackEventData. DONATION_GOAL_STARTS},
    {name:'DONATION GOAL REACHED ',value:slackEventData.DONATION_GOAL_REACHED},
    {name:'TEAM_POSITION',value:slackEventData.TEAM_POSITION},
    {name:'MANAGER SENT EMO',value:slackEventData.MANAGER_SENT_EMO},
    {name:'REWARD LEADERBOARD',value:slackEventData.REWARD_LEADERBOARD},
    {name:'NEW WELLNESS CATEGORY LAUNCHED',value:slackEventData.NEW_WELLNESS_CATEGORY_LAUNCHED},

]

export enum slackEventDataStatus { 
    ALLOWED = 1,
    NOT_ALLOWED = 2
}
export const slackEventStatus = [ 
    {name:'ALLOWED',value:slackEventDataStatus.ALLOWED},
    {name:'NOT ALLOWED ',value:slackEventDataStatus.NOT_ALLOWED},

]


export enum roleType {
    Admin = 1,
    Manager = 2
}

export const challengeCategoryList = [
  { name: 'Step', value: challengeCategory.STEP },
  { name: 'Water', value: challengeCategory.WATER },
  { name: 'Counting', value: challengeCategory.COUNTING },
  { name: 'Points', value: challengeCategory.POINTS },
  { name: 'Sleep', value: challengeCategory.SLEEP },
  { name: 'Screen Time', value: challengeCategory.SCREEN_TIME },
  { name: 'Goal', value: challengeCategory.GOAL },
  { name: 'Quiz', value: challengeCategory.QUIZ },
  { name: 'Pushup counting', value: challengeCategory.PUSHUP_COUNTING },
  { name: 'Gratitude', value: challengeCategory.GRATITUDE },
  { name: 'Social Break', value: challengeCategory.SOCIAL_BREAK },
  { name: 'Meditation', value: challengeCategory.MEDITATION },
  { name: 'Combination', value: challengeCategory.COMBINATION },
  { name: 'Co2', value: challengeCategory.CO2_CHALLENGE },
  { name: 'Language', value: challengeCategory.LANGUAGE_CHALLENGE },
  { name: 'Network challenge', value: challengeCategory.NETWORK_CHALLENGE },
  {name: 'Stairs', value: challengeCategory.STAIRS}, 
  {name: 'SO_GR', value: challengeCategory.SO_GR}, 
  {name: 'LA_COM', value: challengeCategory.LA_COM},
  {name: 'PICTURE_CHALLENGE', value: challengeCategory.PICTURE_CHALLENGE}
];

export const defaultChallengeCategoryList = [
  { name: 'Step', value: challengeCategory.STEP },
  { name: 'Water', value: challengeCategory.WATER },
  { name: 'Counting', value: challengeCategory.COUNTING },
  { name: 'Points', value: challengeCategory.POINTS },
  { name: 'Sleep', value: challengeCategory.SLEEP },
  { name: 'Screen Time', value: challengeCategory.SCREEN_TIME },
  { name: 'Goal', value: challengeCategory.GOAL },
  { name: 'Quiz', value: challengeCategory.QUIZ },
  { name: 'Pushup counting', value: challengeCategory.PUSHUP_COUNTING },
  { name: 'Gratitude', value: challengeCategory.GRATITUDE },
  { name: 'Social Break', value: challengeCategory.SOCIAL_BREAK },
  { name: 'Meditation', value: challengeCategory.MEDITATION },
  { name: 'Combination', value: challengeCategory.COMBINATION },
  { name: 'Co2', value: challengeCategory.CO2_CHALLENGE },
  { name: 'Language', value: challengeCategory.LANGUAGE_CHALLENGE },
  { name: 'Network challenge', value: challengeCategory.NETWORK_CHALLENGE },
  {name: 'Stairs', value: challengeCategory.STAIRS},
  {name: 'SO_GR', value: challengeCategory.SO_GR}, 
  {name: 'LA_COM', value: challengeCategory.LA_COM},
  {name: 'PICTURE_CHALLENGE', value: challengeCategory.PICTURE_CHALLENGE}
];

export enum programStatus {
    ongoing = 1,
    expired = 2,
    upcoming = 3
}

export const challengeImagesList = [
    { 
        type: challengeCategory.STEP,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434503110.png'
    },
    { 
        type: challengeCategory.WATER,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434535417.png'
    },
    { 
        type: challengeCategory.COUNTING,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434562313.png'
    },
    { 
        type: challengeCategory.POINTS,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434591589.png'
    },
    { 
        type: challengeCategory.SLEEP,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434618109.png'
    },
    { 
        type: challengeCategory.SCREEN_TIME,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434649402.png'
    },
    { 
        type: challengeCategory.GOAL,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434681561.png'
    },
    { 
        type: challengeCategory.QUIZ,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434715184.png'
    },
    { 
        type: challengeCategory.PUSHUP_COUNTING,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434743256.png'
    },
    { 
        type: challengeCategory.GRATITUDE,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434771115.png'
    },
    { 
        type: challengeCategory.SOCIAL_BREAK,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434809459.png'
    },
    { 
        type: challengeCategory.MEDITATION,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434836740.png'
    },
    { 
        type: challengeCategory.COMBINATION,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434864643.png'
    },
    { 
        type: challengeCategory.CO2_CHALLENGE,
        imageUrl: 'https://empida.s3.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434430404.png'
    },
    { 
        type: challengeCategory.LANGUAGE_CHALLENGE,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434896385.png'
    },
    { 
        type: challengeCategory.NETWORK_CHALLENGE,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434919149.png'
    },
    { 
        type: challengeCategory.STAIRS,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434956086.png'
    },
    { 
        type: challengeCategory.LA_COM,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434864643.png'
    },
    { 
        type: challengeCategory.SO_GR,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434809459.png'
    },
    { 
        type: challengeCategory.PICTURE_CHALLENGE,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434809459.png'
    }
];
 
export enum Module {

    Dashboard = 1,
    Challenges = 2,
    Teams = 16, 
    Employees = 4,
    Rewards = 5,
    Ngo = 6,
    Roles = 7,
    SendNotifications = 8,
    SlackIntegration = 9,
    Events =12,
    Recognitionfeature =10,
    WellnessCategories=11,
    department = 12,
    Coaching =13,
    monthlyThemes =14,
    managerAi = 15
}






export const languageList = [
    { name: 'Norwegian', value: 'NO'},
    { name: 'English', value: 'YES'}
]


export const launchWeekList = [
    { name: 'Week 1', value: 1 },
    { name: 'Week 2', value: 2 },
    { name: 'Week 3', value: 3 },
    { name: 'Week 4', value: 4 }
]

